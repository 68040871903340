import React from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question1 from '../components/question1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Bossy Cool Reindeer</title>
        <meta property="og:title" content="Bossy Cool Reindeer" />
      </Helmet>
      <div className="home-hero heroContainer">
        <div className="home-container1">
          <h1 className="home-hero-heading heading1">refurb.ing</h1>
          <span className="home-hero-sub-heading bodyLarge">
            <span>
              <span>
                <span>Revolutionizing the way you buy refurbished tech</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <div className="home-btn-group">
            <button className="buttonFlat">Learn More →</button>
          </div>
        </div>
      </div>
      <div className="home-hero1"></div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container2">
              <span className="overline">
                <span>features</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">
                Why Choose refurb.ing?
              </h2>
              <span className="home-features-sub-heading bodyLarge">
                Discover the benefits of buying from us
              </span>
            </div>
            <div className="home-container3">
              <FeatureCard
                Heading="High-Quality Refurbished Products"
                SubHeading="We offer top-notch refurbished tech products that are thoroughly tested and inspected for quality assurance."
              ></FeatureCard>
              <FeatureCard
                Heading="Affordable Prices"
                SubHeading="Get great deals on our refurbished tech products, saving you money without compromising on quality."
              ></FeatureCard>
              <FeatureCard
                Heading="Boutique products at low cost"
                SubHeading="A variety of exclusive &amp; expensive products at a low cost due to our procurement &amp; repair procedures."
              ></FeatureCard>
              <FeatureCard
                Heading="Environmentally Friendly"
                SubHeading="By purchasing refurbished tech, you contribute to reducing electronic waste and promoting sustainability."
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-pricing"></div>
      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">
            Quality Refurbished Tech at Affordable Prices
          </h1>
          <span className="home-banner-sub-heading bodySmall">
            The Refurb.ing marketplace is one of the greatest places you can
            purchase refurbished technology from, with both cheap &amp;
            affordable prices, to quick shipping rates throughout the United
            Kingdom, why bother going elsewhere!
          </span>
          <button className="buttonFilled">Read More</button>
        </div>
      </div>
      <div className="home-faq">
        <div className="faqContainer">
          <div className="home-faq1">
            <div className="home-container4">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text20 heading2">Common questions</h2>
              <span className="home-text21 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="home-container5">
              <Question1
                Answer="Refurb Tech UK is a brand that specializes in providing refurbished technology products."
                Question="What is Refurb Tech UK?"
              ></Question1>
              <Question1
                Answer="Yes, all our refurbished products go through a rigorous testing process to ensure their reliability."
                Question="Are the refurbished products reliable?"
              ></Question1>
              <Question1
                Answer="We do NOT provide any form of warranty or refund poilicy for any item that is sold as refurbished. This is due to the nature of the product, however we assure you that our quality assurance teams do all they can to verify products before they're sent to you."
                Question="What warranty do you offer for refurbished products?"
              ></Question1>
              <Question1
                Question="How is the quality of the refurbished products?"
                Answer="All products come in 1:1 packaging of the original item, as well as identical packing &amp; information slips provided with your order."
              ></Question1>
              <Question1
                Answer="We sell our products on eBay, Vinted &amp; Facebook primarily. If you're interested in a bulk order or a stock check, please reach out to us @ info@refurb.ing"
                Question="Where can I purchase from you?"
              ></Question1>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer"></div>
    </div>
  )
}

export default Home
